import $http from '../../../utils/http.js';

/**
 * 账号管理权限列表
 * @param data
 */
export function postAuthGroupList(data) {
    return $http.post('/account/getroleinfo', data, 'loadingDiv');
}

/**
 * 账号管理列表
 * @param data
 */
export function getAccountManageList(data) {
    return $http.get('/account/index', data, 'loadingDiv');
}

/**
 * 账号管理列表锁定/未锁定操作
 * @param data
 */
export function accountManageChangeLockAction(data) {
    return $http.post('/account/lockuser', data, 'loadingDiv');
}

/**
 * 账号管理列表删除操作
 * @param data
 */
export function accountManageDeleteAction(data) {
    return $http.post('/account/deleteuser', data, 'loadingDiv');
}

/**
 * 账号管理列表添加用户
 * @param data
 */
export function accountManageAddAction(data) {
    return $http.post('/account/adduser', data, 'loadingDiv');
}

/**
 * 账号管理列表编辑按钮数据
 * @param data
 */
export function postEditAccountData(data) {
    return $http.post('/account/edituser', data, 'loadingDiv');
}

/**
 * 账号管理列表编辑用户操作
 * @param data
 */
export function accountManageEditAction(data) {
    return $http.post('/account/saveedituser', data, 'loadingDiv');
}

/**
 * 账号管理列表批量添加用户
 * @param data
 */
 export function accountManageBatchAddUser(data) {
    return $http.post('/account/batchadduser', data, 'loadingDiv');
}