<template>
    <div>
        <el-form ref="addEditForm" :model="addEditForm" :rules="rules" label-width="120px" label-position="right">
            <el-form-item label="用户名" prop="userName">
                <el-input class="w-240" size="small" v-model="addEditForm.userName"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input class="w-240" size="small" type="password" v-model="addEditForm.password" maxlength="18"
                    autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPassword">
                <el-input class="w-240" size="small" type="password" v-model="addEditForm.confirmPassword"
                    maxlength="18" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="姓名" prop="name">
                <el-input class="w-240" size="small" v-model="addEditForm.name"></el-input>
            </el-form-item>
            <el-form-item label="权限:">
                <el-select class="w-240" size="small" multiple placeholder="请选择权限" v-model="addEditForm.permissionsArr">
                    <el-option v-for="item in authGroupList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="邮箱:" prop="email">
                <el-input class="w-240" size="small" v-model="addEditForm.email" placeholder="请输入邮箱地址"></el-input>
            </el-form-item>
            <el-form-item style="margin-top: 50px">
                <el-button type="primary" size="small" @click="$emit('close', false)">取消</el-button>
                <el-button type="primary" size="small" @click="handleConfirmAddBtn">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {
    postAuthGroupList,
    postEditAccountData,
    accountManageAddAction,
    accountManageEditAction
} from '@/common/api/setting/accountManage.js';
export default {
    name: 'accountManageAddEdit',
    props: {
        accountManageAddEditData: {
            type: Object,
            default: () => { }
        },
        type: {
            type: String,
            default: ''
        }
    },
    data() {
        const validatePassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('密码不能为空'));
            } else {
                if (this.addEditForm.confirmPassword !== '') {
                    this.$refs.addEditForm.validateField('confirmPassword');
                }
                callback();
            }
        };
        const validateConfirmPassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('确认密码不能为空'));
            } else if (value !== this.addEditForm.password) {
                callback(new Error('输入的密码不一致'));
            } else {
                callback();
            }
        };
        return {
            addEditForm: {
                // 用户id
                userId: '',
                // 用户名
                userName: '',
                // 密码
                password: '',
                // 确认密码
                confirmPassword: '',
                // 姓名
                name: '',
                // 权限
                permissionsArr: [],
                // 邮箱
                email: ''
            },
            rules: {
                userName: [
                    {
                        required: true,
                        message: '用户名不能为空',
                        trigger: 'blur'
                    }
                    // ,
                    // {
                    //     pattern: /^[A-Za-z]+$/,
                    //     message: '用户名只能是英文字符',
                    //     trigger: 'blur'
                    // }
                ],
                password: [
                    {
                        required: true,
                        validator: validatePassword,
                        trigger: 'blur'
                    },
                    {
                        pattern: /^[A-Za-z0-9]+$/,
                        message: '密码只能是英文和数字组成，长度在18个字符内',
                        trigger: 'blur'
                    }
                ],
                confirmPassword: [
                    {
                        required: true,
                        validator: validateConfirmPassword,
                        trigger: 'blur'
                    },
                    {
                        pattern: /^[A-Za-z0-9]+$/,
                        message: '密码只能是英文和数字组成，长度在18个字符内',
                        trigger: 'blur'
                    }
                ],
                name: [
                    {
                        required: true,
                        message: '姓名不能为空',
                        trigger: 'blur'
                    },
                    {
                        pattern: /^[\u4E00-\u9FA5A-Za-z]+$/,
                        message: '姓名只能是中文和英文组成',
                        trigger: 'blur'
                    }
                ],
                email: [
                    {
                        pattern: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                        message: '邮箱格式不正确，不含中文，开头必须是英文或者数字',
                        trigger: 'blur'
                    }
                ]
            },
            // 权限列表
            authGroupList: []
        };
    },
    created() {
        this.queryAuthGroupData();
        if (this.type == 'edit') {
            this.addEditForm.userId = this.accountManageAddEditData.userId;
            this.queryEditEchoData();
        }
    },
    methods: {
        // 查询权限列表
        queryAuthGroupData() {
            postAuthGroupList()
                .then(res => {
                    if (res.code == 200) {
                        this.authGroupList = res.MxRole;
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 查询编辑弹窗回显数据
        queryEditEchoData() {
            let params = {
                UserID: this.addEditForm.userId
            };
            postEditAccountData(params)
                .then(res => {
                    const { code, userInfo } = res;
                    if (code == 200) {
                        let {
                            UserID: userId,
                            UserName: userName,
                            password,
                            confirmPassword,
                            RealName: name,
                            Roles: permissionsArr,
                            Email: email
                        } = userInfo;
                        this.addEditForm = {
                            userId,
                            userName,
                            password,
                            confirmPassword,
                            name,
                            permissionsArr,
                            email
                        };
                        // console.log('this.addEditForm: ', this.addEditForm);
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 处理确定按钮
        handleConfirmAddBtn() {
            this.$refs.addEditForm.validate(valid => {
                if (valid) {
                    let addParams = {
                        UserName: this.addEditForm.userName,
                        Password: this.addEditForm.password,
                        RePassword: this.addEditForm.confirmPassword,
                        RealName: this.addEditForm.name,
                        Email: this.addEditForm.email,
                        RoleIDs: this.addEditForm.permissionsArr
                    };
                    let editParams = {
                        ...addParams,
                        UserID: this.addEditForm.userId
                    };
                    let params = this.type == 'edit' ? editParams : addParams;
                    let submitFunc = this.type == 'edit' ? accountManageEditAction : accountManageAddAction;
                    submitFunc(params)
                        .then(res => {
                            if (res.code == 200) {
                                this.$emit('close', false);
                                this.$nextTick(() => {
                                    this.$refs.addEditForm.resetFields();
                                });
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        }
    },
    watch: {
        'accountManageAddEditData.userId': function (newVal) {
            this.addEditForm.userId = newVal;
            this.queryEditEchoData();
        }
    }
};
</script>

<style scoped>
.w-240 {
    width: 240px;
}
</style>