<template>
    <div class="batch-add-user-page">
        <div class="batch-add-title">批量添加用户</div>
        <div class="batch-add-wrap">
            <el-form :inline="true" ref="batchAddUserForm" :model="batchAddUserForm" :rules="rules" label-width="80px"
                class="batch-add-user-form">
                <el-row type="flex" justify="space-between" v-for="(item, index) in batchAddUserForm.batchAddUserData"
                    :key="`batchAddUserData${index}`">
                    <!-- <el-col :span="4">
                        <el-form-item
                            label="用户名"
                            :prop="`batchAddUserData[${index}].userName`"
                            :rules="[
                                { required: true, message: '用户名不能为空', trigger: 'blur' },
                                { pattern: /^[A-Za-z]+$/, message: '用户名只能是英文字符', trigger: 'blur' }
                            ]"
                        > -->
                    <el-col :span="4">
                        <el-form-item label="用户名" :prop="`batchAddUserData[${index}].userName`" :rules="[
                            { required: true, message: '用户名不能为空', trigger: 'blur' }
                        ]">
                            <el-input class="w-160" size="small" v-model="item.userName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="姓名" :prop="`batchAddUserData[${index}].name`" :rules="[
                            { required: true, message: '姓名不能为空', trigger: 'blur' },
                            {
                                pattern: /^[\u4E00-\u9FA5A-Za-z]+$/,
                                message: '姓名只能是中文和英文组成',
                                trigger: 'blur'
                            }
                        ]">
                            <el-input class="w-160" size="small" v-model="item.name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="密码" :prop="`batchAddUserData[${index}].password`" :rules="[
                            { required: true, message: '密码不能为空', trigger: 'blur' },
                            {
                                pattern: /^[A-Za-z0-9]+$/,
                                message: '密码只能是英文和数字组成，长度在18个字符内',
                                trigger: 'blur'
                            }
                        ]">
                            <el-input class="w-160" size="small" @change="batchAutoAddPassword(item.password)"
                                v-model="item.password"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="权限">
                            <el-select class="w-160" multiple collapse-tags clearable placeholder="请选择权限" size="small"
                                v-model="item.permissions" @change="batchAutoAddPermissions(item.permissions)">
                                <el-option v-for="auth in authGroupList" :key="auth.id" :label="auth.name"
                                    :value="auth.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="邮箱" :prop="`batchAddUserData[${index}].email`" :rules="{
                            pattern: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                            message: '邮箱格式不正确，不含中文，开头必须是英文或者数字',
                            trigger: 'blur'
                        }">
                            <el-input class="w-160" size="small" v-model="item.email" placeholder="请输入邮箱地址"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="batch-add-btn-warp">
            <el-button style="float: left; margin-left: 20px" type="primary" size="small"
                @click="handleAddBtn(batchAddUserForm.batchAddUserData)">+添加</el-button>
            <el-button style="margin-right: 50px" type="primary" size="medium" @click="handleSaveBtn">保存</el-button>
            <el-button type="primary" size="medium" @click="handleCancelBtn">取消</el-button>
        </div>
    </div>
</template>

<script>
import { postAuthGroupList, accountManageBatchAddUser } from '@/common/api/setting/accountManage.js';
export default {
    name: 'batchAddUserPage',
    data() {
        return {
            batchAddUserForm: {
                batchAddUserData: [
                    {
                        // 用户名
                        userName: '',
                        // 姓名
                        name: '',
                        // 密码
                        password: '',
                        // 权限
                        permissions: [],
                        // 邮箱
                        email: ''
                    }
                ]
            },
            rules: {
                // userName: [
                //     {
                //         required: true,
                //         message: '用户名不能为空',
                //         trigger: 'blur'
                //     },
                //     {
                //         pattern: /^[A-Za-z]+$/,
                //         message: '用户名只能是英文字符',
                //         trigger: 'blur'
                //     }
                // ],
                // name: [
                //     {
                //         required: true,
                //         message: '姓名不能为空',
                //         trigger: 'blur'
                //     },
                //     {
                //         pattern: /^[\u4E00-\u9FA5A-Za-z]+$/,
                //         message: '姓名只能是中文和英文组成',
                //         trigger: 'blur'
                //     }
                // ],
                // password: [
                //     {
                //         required: true,
                //         message: '密码不能为空',
                //         trigger: 'blur'
                //     },
                //     {
                //         pattern: /^[A-Za-z0-9]+$/,
                //         message: '密码只能是英文和数字组成，长度在18个字符内',
                //         trigger: 'blur'
                //     }
                // ],
                // email: [
                //     {
                //         pattern: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                //         message: '邮箱格式不正确，不含中文，开头必须是英文或者数字',
                //         trigger: 'blur'
                //     }
                // ]
            },
            // 权限列表
            authGroupList: []
        };
    },
    mounted() {
        this.queryBatchAuthGroupData();
    },
    methods: {
        batchAutoAddPassword(value) {
            this.batchAddUserForm.batchAddUserData.forEach(item => {
                if (item.password == '') {
                    item.password = value;
                }
            });
        },
        batchAutoAddPermissions(value) {
            this.batchAddUserForm.batchAddUserData.forEach(item => {
                if (item.permissions == '') {
                    item.permissions = value;
                }
            });
        },
        // 查询权限列表
        queryBatchAuthGroupData() {
            postAuthGroupList()
                .then(res => {
                    if (res.code == 200) {
                        this.authGroupList = res.MxRole;
                        this.authGroupList.push({
                            id: 'DITTO',
                            name: '同上'
                        });
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 处理添加按钮
        handleAddBtn(list) {
            this.batchAddUserForm.batchAddUserData.push({
                userName: '',
                name: '',
                password: '',
                permissions: [],
                email: ''
            });
            this.batchAddUserForm.batchAddUserData.forEach(item => {
                for (let i = 0; i < list.length; i++) {
                    if (item.password == '' && item.permissions == '') {
                        item.password = list[list.length - 2].password;
                        item.permissions = ['DITTO'];
                        break;
                    }
                }
            });
        },
        // 处理保存按钮
        handleSaveBtn() {
            this.$refs.batchAddUserForm.validate(valid => {
                if (valid) {
                    let params = this.batchAddUserForm.batchAddUserData.map((item, index) => {
                        return {
                            UserName: item.userName,
                            RealName: item.name,
                            Password: item.password,
                            RoleIDs: item.permissions,
                            Email: item.email
                        };
                    });
                    accountManageBatchAddUser(params)
                        .then(res => {
                            let { code } = res;
                            if (code == 200) {
                                this.$emit('cancel');
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        // 处理取消按钮
        handleCancelBtn() {
            this.$emit('cancel');
        }
    }
};
</script>

<style scoped>
.batch-add-user-page {
    width: 100%;
    margin: 30px auto;
    text-align: center;
    font-size: 24px;
}

.batch-add-user-page>div:first-child {
    margin-bottom: 60px;
}

.batch-add-btn-warp::after {
    display: block;
    content: '';
    clear: both;
    height: 0;
    visibility: hidden;
}

.batch-add-user-page>.batch-add-btn-warp {
    margin-top: 60px;
}

.w-160 {
    width: 160px;
}
</style>