<template>
    <div>
        <el-row>
            <div style="width: 100%">
                <el-form size="small" :inline="true" :model="searchForm" class="demo-form-inline">
                    <el-form-item label="名称：">
                        <el-input style="width: 200px" v-model="searchForm.searchVal" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="时间：">
                        <el-date-picker v-model="searchForm.dateTimeArr" type="datetimerange"
                            value-format="YYYY-MM-DD HH:mm:ss" range-separator="-" start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="权限组：">
                        <el-select v-model="searchForm.authValue" style="width: 150px" placeholder="全部" clearable>
                            <el-option v-for="item in authGroupList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleSearch">查询</el-button>
                    </el-form-item>
                    <el-form-item style="float: right; margin-right: 0">
                        <el-button style="width: 100px" type="primary" :disabled="!permissionsAction.batchAdd"
                            @click="handleBatchAddUser">批量添加</el-button>
                        <el-button style="width: 100px" type="primary" :disabled="!permissionsAction.add"
                            @click="handleAddEditUser('add')">添加用户</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div style="width: 100%">
                <el-table :header-cell-style="{ background: '#F2F2F2' }" :data="accountManageDataList" border
                    align="center" style="width: 100%">
                    <el-table-column label="序号" width="90px" type="index" :index="indexMethod" align="center">
                    </el-table-column>
                    <el-table-column prop="Name" label="姓名" align="center"></el-table-column>
                    <el-table-column prop="Account" label="用户名" align="center"></el-table-column>
                    <el-table-column prop="RoleName" label="分组" align="center"></el-table-column>
                    <el-table-column prop="LoginNumber" label="访问次数" align="center"> </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template v-slot="scope">
                            <el-button :disabled="!permissionsAction.edit" @click="handleAddEditUser('edit', scope.row)"
                                type="text" size="small">编辑</el-button>
                            <el-button :disabled="!permissionsAction.enable" @click="handleLockBtn(scope.row)"
                                type="text" size="small">{{ scope.row.IsLock === 0 ? '未锁定' : '锁定' }}</el-button>
                            <el-button :disabled="!permissionsAction.del" @click="handleDeleteBtn(scope.row)"
                                type="text" size="small">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: right; margin-top: 25px" v-if="page.total != 0">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page.page" :page-sizes="[10, 15, 30, 35]" :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                    </el-pagination>
                </div>
            </div>
        </el-row>
        <!-- 添加用户/编辑弹窗 -->
        <dialogFrame :show="dialogFormAddEditVisible" @showDialog="showDialog" :title="type == 'edit' ? '编辑用户' : '添加用户'"
            :footer="false" width="540px" center>
            <accountManageAddEdit @close="closeDialog" :accountManageAddEditData="accountManageAddEditData"
                :type="type"></accountManageAddEdit>
        </dialogFrame>
    </div>
</template>

<script>
import dialogFrame from '@/components/common/dialog/dialog-frame.vue';
import accountManageAddEdit from '../../component/setting/accountManage/accountManageAddEdit.vue';
import jsonTool from '@/utils/jsonTool.js';
import {
    postAuthGroupList,
    getAccountManageList,
    accountManageChangeLockAction,
    accountManageDeleteAction
} from '@/common/api/setting/accountManage.js';
export default {
    name: 'accountManagePage',
    components: {
        dialogFrame,
        accountManageAddEdit
    },
    data() {
        return {
            searchForm: {
                searchVal: '',
                dateTimeArr: [],
                authValue: ''
            },
            // 权限组列表
            authGroupList: [],
            page: {
                page: 1,
                pageSize: 10,
                total: 0
            },
            // 账号管理列表
            accountManageDataList: [],
            // 权限操作集合
            permissionsAction: {},
            // 是否显示添加/编辑用户
            dialogFormAddEditVisible: false,
            // 添加/编辑类型
            type: '',
            // 添加/编辑弹窗数据
            accountManageAddEditData: {}
        };
    },
    mounted() {
        this.queryAuthGroupData();
        this.queryAccountManageData();
    },
    methods: {
        showDialog(val) {
            this.dialogFormAddEditVisible = val;
        },
        closeDialog(val) {
            this.dialogFormAddEditVisible = val;
            this.queryAccountManageData();
        },
        // userColumnName(row) {
        //     return row.permissions ? row.permissions.join('、') : '';
        // },
        // 查询权限组筛选项
        queryAuthGroupData() {
            postAuthGroupList()
                .then(res => {
                    if (res.code == 200) {
                        this.authGroupList = res.MxRole;
                        this.authGroupList.unshift({
                            id: '',
                            name: '全部'
                        });
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 查询账号表格数据
        queryAccountManageData() {
            let startTime = this.searchForm.dateTimeArr[0];
            let endTime = this.searchForm.dateTimeArr[1];
            let params = {
                Name: this.searchForm.searchVal,
                StartTime: startTime,
                EndTime: endTime,
                RoleID: this.searchForm.authValue,
                Page: this.page.page,
                Len: this.page.pageSize
            };
            getAccountManageList(params)
                .then(res => {
                    let { code, userList, Power } = res;
                    if (code == 200) {
                        this.accountManageDataList = jsonTool(userList);
                        let { Add: add, BatchAdd: batchAdd, Delete: del, Edit: edit, Enable: enable } = Power;
                        this.permissionsAction = {
                            add,
                            batchAdd,
                            del,
                            edit,
                            enable
                        };
                        this.page.total = res.count;
                        // console.log('this.accountManageDataList: ', this.accountManageDataList);
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 处理查询按钮
        handleSearch() {
            this.queryAccountManageData();
        },
        // 处理批量添加按钮
        handleBatchAddUser() {
            this.$emit('switchBatchAddUserPage');
        },
        // 处理添加用户/编辑按钮
        handleAddEditUser(type, row) {
            this.type = type;
            if (type == 'edit') {
                let { UserID: userId } = row;
                this.accountManageAddEditData = {
                    userId
                };
            }
            this.dialogFormAddEditVisible = true;
        },
        // 处理锁定/未锁定按钮
        handleLockBtn(row) {
            let lockName = row.IsLock === 0 ? '锁定' : '解锁';
            let isLock = row.IsLock === 0 ? 1 : 0;
            let params = {
                UserID: row.UserID,
                IsLock: isLock
            };
            this.$confirm(`是否进行${lockName}当前用户账号操作吗？`, '锁定', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true
            })
                .then(() => {
                    accountManageChangeLockAction(params)
                        .then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    type: 'success',
                                    message: `${lockName}成功!`,
                                    center: true
                                });
                                this.queryAccountManageData();
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        // 处理删除按钮
        handleDeleteBtn(row) {
            let params = {
                UserID: row.UserID
            };
            this.$confirm('确认删除当前用户账户吗？', '删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true
            })
                .then(() => {
                    accountManageDeleteAction(params)
                        .then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功!',
                                    center: true
                                });
                                this.queryAccountManageData();
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        // 下标序号
        indexMethod(index) {
            return this.page.pageSize * (this.page.page - 1) + 1 + index;
        },
        // 分页处理
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.queryAccountManageData();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.queryAccountManageData();
        }
    }
};
</script>

<style lang="scss" scoped>

</style>