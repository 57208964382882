<template>
    <div>
        <el-row style="padding: 25px">
            <accountManagePage v-if="showPage == 'accountManage'" @switchBatchAddUserPage="switchBatchAddUserPage"></accountManagePage>
            <batchAddUserPage v-if="showPage == 'batchAddUser'" @cancel="cancel"></batchAddUserPage>
        </el-row>
    </div>
</template>

<script>
import accountManagePage from './accountManagePage.vue';
import batchAddUserPage from './batchAddUserPage.vue';
export default {
    components: {
        accountManagePage,
        batchAddUserPage
    },
    data() {
        return {
            showPage: 'accountManage'
        }
    },
    methods: {
        switchBatchAddUserPage(){
            this.showPage = 'batchAddUser';
        },
        cancel(){
            this.showPage = 'accountManage'
        }
    }
};
</script>

<style lang="scss" scoped>
</style>